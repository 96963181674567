import React, { useState } from 'react';

function UpdateProfileModal({ field, value, onSave, onClose }) {
  const [inputValue, setInputValue] = useState(value);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOtp] = useState('');

  const handleSaveClick = () => {
    if (field === 'Email') {
      // Trigger OTP sending logic here
      setShowOTPInput(true);
    } else {
      onSave(inputValue);
      onClose();
    }
  };

  const handleVerifyOTP = () => {
    // Add logic to verify OTP
    if (otp === '123456') { // Replace with real OTP verification logic
      onSave(inputValue);
      onClose();
    } else {
      alert('Invalid OTP');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Update {field}</h2>

        {showOTPInput ? (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Enter OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              placeholder="Enter OTP"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={onClose} className="bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300">
                Cancel
              </button>
              <button onClick={handleVerifyOTP} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
                Verify OTP
              </button>
            </div>
          </div>
        ) : (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">{field}</label>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              placeholder={`Enter new ${field}`}
            />
            <div className="flex justify-end space-x-2">
              <button onClick={onClose} className="bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300">
                Cancel
              </button>
              <button onClick={handleSaveClick} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateProfileModal;
