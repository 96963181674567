import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import TopBar from './TopBar';
import TopDrawer from './TopDrawer';
import QuestionPractice from './QuestionPractice';
import SubjectSelection from './SubjectSelection';
import ProfilePage from './ProfilePage'
import QuestionBuckets from './QuestionBuckets'
import BookmarksPage from './BookmarksPage';
import QuestionHistoryPage from './QuestionHistoryPage'

function MainLayout() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleMenuClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="flex w-screen h-screen flex-col">
      <TopBar onMenuClick={handleMenuClick} className="fixed top-0 w-full" />
      <div className="flex flex-grow overflow-hidden">
        <div className="flex-grow overflow-y-auto">
          <Routes>
            <Route index element={<SubjectSelection />} />
            <Route path="subject-selection" element={<SubjectSelection />} />
            <Route path="question" element={<QuestionPractice />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="buckets" element={<QuestionBuckets />} />
            <Route path="bookmarks" element={<BookmarksPage />} />
            <Route path="history" element={<QuestionHistoryPage />} />

          </Routes>
        </div>
      </div>
      <TopDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
    </div>
  );
}

export default MainLayout;
