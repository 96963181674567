import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import UpdateProfileModal from './UpdateProfileModal';
import toast from 'react-hot-toast';

function ProfilePage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalField, setModalField] = useState('');
  const [modalValue, setModalValue] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('/jeet-backend/get-profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          }
        });

        if (response.ok) {
          const data = await response.json();
          setName(data.name);
          setEmail(data.email);
          setMobile(data.mobile_number);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.detail}`);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast.error('An error occurred while fetching user details.');
      }
    };

    fetchUserDetails();
  }, []);

  const handleEditClick = (field) => {
    setModalField(field);
    setModalValue(field === 'Name' ? name : email);
    setIsModalOpen(true);
  };

  const handleSave = async (newValue) => {
    if (modalField === 'Name') {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch('/jeet-backend/update-name', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          },
          body: JSON.stringify({ new_name: newValue })
        });

        if (response.ok) {
          const data = await response.json();
          toast.success(data.message);
          setName(newValue);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.detail}`);
        }
      } catch (error) {
        console.error('Error updating name:', error);
        toast.error('An error occurred while updating the name.');
      }
    } else if (modalField === 'Email') {
      setEmail(newValue);
    }

    setIsModalOpen(false);
  };

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await fetch('/jeet-backend/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
      });

      if (response.ok) {
        toast.success('Password updated successfully');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('An error occurred while updating the password.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Profile</h2>

        <div className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <div className="text-base text-gray-800">{name}</div>
            </div>
            <FaEdit className="text-gray-500 cursor-pointer" onClick={() => handleEditClick('Name')} />
          </div>

          <div className="flex justify-between items-center mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <div className="flex justify-between">
                <div className="text-base text-gray-800">{email}</div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Number</label>
              <div className="text-gray-800">{mobile}</div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">Change Password</h3>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Old Password</label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Confirm New Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <button onClick={handlePasswordReset} className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">
            Reset Password
          </button>
        </div>
      </div>

      {isModalOpen && (
        <UpdateProfileModal
          field={modalField}
          value={modalValue}
          onSave={handleSave}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default ProfilePage;
