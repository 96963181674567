import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function VerifyEmailPage() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const emailParam = query.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
    
    // Start timer for resend functionality
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          setCanResend(true);
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    
    return () => clearInterval(interval);
  }, [location.search]);

  const handleVerify = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('/jeet-backend/verify-email', {
        email,
        code,
      });
      if (response.status === 200) {
        setSuccess('Email verified successfully!');
        setTimeout(() => navigate('/login'), 2000); // Redirect to login after 2 seconds
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred');
    }
  };

  const handleResend = async () => {
    try {
      await axios.post('/jeet-backend/resend-verification-email', { email });
      setError('');
      setSuccess('Verification email resent successfully.');
      setTimer(30);
      setCanResend(false);
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <div className="min-h-screen w-screen bg-gray-100 flex flex-col justify-center items-center p-6">
      <div className="max-w-lg bg-white p-6 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">JEET.io</h1>

        <h2 className="text-lg font-bold mb-4">Verify Your Email</h2>

        <p className="text-gray-600 mb-4">
          An email with a verification code has been sent to: <strong className="font-sans">{email}</strong>
        </p>

        <form onSubmit={handleVerify}>
          {/* Verification Code Input */}
          <input
            type="text"
            placeholder="Enter verification code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
          />

          {/* Resend OTP */}
          {canResend && (
            <button
              onClick={handleResend}
              className="text-blue-500 text-sm font-sans py-2 hover:text-blue-700 mb-6"
            >
              Resend Verification Email
            </button>
          )}

          {!canResend && (
            <p className="text-gray-600 text-sm mt-2 mb-6">
              You can resend the verification email in {timer} seconds.
            </p>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-sans font-bold py-2 px-4 rounded-full hover:bg-blue-700 mb-4"
          >
            Verify
          </button>

          {/* Error Message */}
          {error && <p className="text-red-500 mb-4">{error}</p>}

          {/* Success Message */}
          {success && <p className="text-green-500 mb-4">{success}</p>}
        </form>
      </div>
    </div>
  );
}

export default VerifyEmailPage;
