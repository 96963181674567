import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(''); // Clear any previous error message
    try {
      const response = await fetch('/jeet-backend/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.detail || 'Login failed');
      } else {
        // Save the token in localStorage
        localStorage.setItem('token', data.access_token);

        // Redirect to the homepage or dashboard after successful login
        navigate('/practice/subject-selection');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen w-screen bg-gray-100 flex flex-col justify-center items-center p-6">
      <div className="max-w-2xl bg-white p-6 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">Login to <Link to="/" className="underline">JeeStudy.in</Link></h1>

        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Email Input */}
        <input
          type="email"
          placeholder="Email ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 mb-4 border border-gray-300 rounded"
        />

        {/* Password Input */}
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-3 mb-2 border border-gray-300 rounded"
        />

        {/* Forgot Password */}
        <div className="text-right mb-4">
          <button className="text-sm text-blue-500 hover:underline">
            Forgot password?
          </button>
        </div>

        {/* Login Button */}
        <button
          onClick={handleLogin}
          className="w-full bg-blue-500 text-white font-sans font-bold py-2 px-4 rounded-full hover:bg-blue-700 mb-4"
        >
          Login
        </button>

        {/* Sign Up Instead */}
        <p className="text-gray-600 mt-4">
          Don't have an account?{' '}
          <Link to="/signup" className="text-blue-500 hover:underline">
            Sign up instead.
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
