import { FaPause, FaPlay } from 'react-icons/fa';

// Timer Component
function Timer({ timer, isRunning, handleToggleTimer }) {
  return (
    <div className="text-white text-sm rounded-full bg-gray-600 px-4 py-2">
      <div className="flex items-center">
        <span className="mr-2">Timer:</span>
        <span>{`${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</span>
        <button onClick={handleToggleTimer} className="ml-2">
          {isRunning ? (
            <FaPause title="Pause timer" />
          ) : (
            <FaPlay title="Resume timer" />
          )}
        </button>
      </div>
    </div>
  );
}


export default Timer;