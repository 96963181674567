import React from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function TopBar({ onMenuClick }) {
  return (
    <div className="w-full bg-white text-black border-b flex justify-between items-center px-4 py-2">
      <div className="text-center flex-grow">
        <h1 className="text-2xl font-bold"><Link to="/">JeeStudy.in</Link></h1>
      </div>
      <button className="text-black" onClick={onMenuClick}>
        <FaBars size={24} />
      </button>
    </div>
  );
}

export default TopBar;
