import React, { useState } from 'react';
import BookmarkModal from './BookmarkModal';
import { useSearchParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

function capitalizeFirstChar(str) {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function QuestionDetails({ questionData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const questionId = searchParams.get("id");

  const handleBookmarkClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveBookmark = async (note) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch('/jeet-backend/add-bookmark', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          question_id: parseInt(questionId),
          note,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      toast.success('Bookmark added!')

    } catch (error) {
      console.error('Failed to bookmark question:', error);
    }
  };

  const handleShareClick = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this question',
        text: `Here's a question from ${capitalizeFirstChar(questionData.subject)}: ${questionData.source}.`,
        url: window.location.href,
      })
      .then(() => console.log('Share successful'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      // console.error('Web Share API not supported in this browser');
      navigator.clipboard.writeText(window.location.href)
        .then(() => toast.success('Shareable link copied to your clipboard!'))
        .catch((error) => console.error('Failed to copy link:', error));
    }
  };

  return (
    <div className="mb-4">
      <img src={questionData.image_path} alt="Question" className="w-full h-auto" />
      <div className="flex flex-col md:flex-row justify-between mt-4">
        <div className="flex flex-row gap-1">
          <div className="text-sm text-gray-600 mb-4">{capitalizeFirstChar(questionData.subject)} of</div>
          <div className="text-sm text-gray-600 mb-2">{questionData.source}</div>
        </div>
        <div className="flex justify-between items-start">
          <div className="flex space-x-3">
            <button
              className="underline text-gray-400 text-sm"
              onClick={handleBookmarkClick}
            >
              Doubt/Bookmark
            </button>
            <button 
              className="underline text-gray-400 text-sm"
              onClick={handleShareClick} >
              Share
            </button>
          </div>
        </div>
      </div>

      <BookmarkModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveBookmark}
      />
    </div>
  );
}

export default QuestionDetails;
