import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

function LandingPage() {
  const [questionCounts, setQuestionCounts] = useState({
    total_questions: 0,
    questions_per_subject: {
      mathematics: 0,
      chemistry: 0,
      physics: 0,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestionCounts = async () => {
      try {
        const response = await fetch('/jeet-backend/get-question-counts');
        const data = await response.json();
        setQuestionCounts(data);
      } catch (error) {
        console.error('Error fetching question counts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionCounts();
  }, []);

  if (loading) {
    return <p className="flex flex-row justify-center items-center">Loading...</p>;
  }

  return (
    <div className="min-h-screen w-screen bg-gray-100 flex flex-col justify-center items-center p-6">
      <div className="max-w-2xl bg-white p-6 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">JeeStudy.in</h1>

        <p className="mb-6 text-justify">
          On the day of the JEE Advanced Exam you will be asked to solve 2 papers.
          Each paper will have approximately 55 questions.
          During your preparation for JEE, you are not expected to become
          the master of Physics, Chemistry and Mathematics as it is impossible
          for one person to master all three of these huge subjects,
          rather, what is expected is that you become a master of solving
          JEE Advanced level problems within the exam time frame. 

          <br/><br/>
          If you recall, we used to solve the maze puzzle problems as kids, and one of the easiest
          ways to solve the maze puzzles is to solve it from back to front,
          where you start from the exit and reach the start point, technically
          this would be considered cheating the puzzle, but we must apply the same approach
          in our JEE preparation.
          <br/><br/>

          We must start from the end of the puzzle, which means, we must learn by solving
          previous year JEE questions and learn to deconstruct them efficiently and 
          to do so we must practice as many of them as possible.
          <br/><br/>

          JeeStudy.in aims to be a simple free source of past year questions & detailed answers for any student
          who aims to do a meaningful and calculated preparation for JEE Mains & Advanced.
          <br/><br/>
          We provide detailed step-by-step solutions to each question, so you can be confident that you can
          solve the same question again in the future.

        </p>

        <div className="flex flex-col justify-center items-center">
          <p className="mb-4">
            Currently, our question & answer bank has a total of {questionCounts.total_questions} questions:
          </p>
          <ul className="mb-6 text-left">
            <li>• {questionCounts.questions_per_subject.mathematics} mathematics questions</li>
            <li>• {questionCounts.questions_per_subject.chemistry} chemistry questions</li>
            <li>• {questionCounts.questions_per_subject.physics} physics questions</li>
          </ul>
        </div>

        <Link to="/practice" className="inline-flex items-center bg-blue-500 text-white font-sans font-bold py-2 px-4 rounded-full hover:bg-blue-700">
          START PRACTISING <FaArrowRight className="ml-2" />
        </Link>

        <p className="mt-4 text-xs text-gray-500">
          Note: New questions are added on a weekly basis.
        </p>
      </div>
    </div>
  );
}

export default LandingPage;



/*
        <p className="mb-6">
          JeeStudy.in is a compilation of previous year IITJEE questions and we have created
          handcrafted detailed explanations for each question and made them freely available
          for all.
        </p>
        <p className="mb-4">
          <u>How should a student study for JEE?</u>
        </p>

        <br/><br/>
          You must learn 
          to uncomplicate each question and simplify it so that you can apply the 
          correct formulas or theory and solve the question. In reality, you need to 
          become an expert at JEE level problem solving with a balance across all 3 subejcts 
          rather than going extremely deep into any one subject.

      <br/><br/>
          The student who understands this truth, can easily navigate the
          difficult journey of JEE preparation.
          <br/><br/>
          Hence, to solve those 110 questions (Paper 1 + Paper 2) on the day of the JEE
          Advanced Exam what must one do?
          <br/><br/>

      <p className="mb-6">
          As you start practising questions on JeeStudy.in we also
          show you a real-time report card which highlights your 
          strong and weak areas of study.
        </p>
    <p className="mt-4 flex items-center justify-center text-sm text-gray-600">
          <span className="animate-pulse bg-green-600 w-2 h-2 rounded-full mr-2"></span>
          389 students studying right now.
        </p>
*/