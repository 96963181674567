import { useState,useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';

// FeedbackSelect Component
function FeedbackSelect({ selectedOption, handleOptionChange }) {
  const [buckets, setBuckets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch buckets from the backend API
    const fetchBuckets = async () => {
      try {
        const response = await fetch('/jeet-backend/get-buckets');
        if (!response.ok) {
          throw new Error('Failed to fetch buckets');
        }
        const data = await response.json();
        setBuckets(data);
      } catch (error) {
        console.error('Error fetching buckets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuckets();
  }, []);

  return (
    <select
      id="questionFeedback"
      value={selectedOption}
      onChange={handleOptionChange}
      className="ml-2 p-2 border rounded-lg text-sm w-full"
    >
      <option value="">Mark this question as</option>
      {loading ? (
        <option>Loading...</option>
      ) : (
        buckets.map((bucket) => (
          <option key={bucket.id} value={bucket.id}>
            {bucket.name}
          </option>
        ))
      )}
    </select>
  );
}

function NavigationButtons({ handlePreviousQuestion,handleNextQuestion, selectedOption, handleOptionChange }) {
  const [loading, setLoading] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);

  const handleNextClick = () => {
    setLoading(true);
    handleNextQuestion().finally(() => setLoading(false));
  };

  const handlePreviousClick = () => {
    setLoadingPrev(true);
    handlePreviousQuestion().finally(() => setLoadingPrev(false));
  };

  return (
    <div className="mt-6 flex justify-between items-center">
      <div>
        <button
          onClick={handlePreviousQuestion}
          className="text-sm bg-gray-600 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-gray-700 flex items-center"
          disabled={loadingPrev}
        >
          {loadingPrev ? (
            <ImSpinner8 className="animate-spin ml-2" />
          ) : (
            <FaArrowLeft className="md:mr-2" />
          )}
          <span className="hidden sm:block">Previous Question</span>
        </button>
      </div>
      <div className="flex justify-center w-7/12 sm:w-4/12">
        <FeedbackSelect 
          selectedOption={selectedOption} 
          handleOptionChange={handleOptionChange} />
      </div>
      <div>
        <button
          onClick={handleNextClick}
          className="text-sm bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 flex items-center"
          disabled={loading}
        >
          <span className="hidden sm:block">Next Question</span>
          {loading ? (
            <ImSpinner8 className="animate-spin ml-2" />
          ) : (
            <FaArrowRight className="md:ml-2" />
          )}
        </button>
      </div>
    </div>
  );
}

export default NavigationButtons