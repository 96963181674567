import React from 'react';
import { FaTimes, FaUser, FaSignOutAlt, FaList, FaBookmark, FaHistory } from 'react-icons/fa';
import { FaBookOpen } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

function TopDrawer({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Remove the token from local storage
    localStorage.removeItem('authToken');
    
    // Redirect the user to the login page
    navigate('/login');
  };

  if (!isOpen) return null;

  return (
    <div onClick={onClose} className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-end">
      <div className="transform transition-transform duration-300 ease-in-out translate-x-0 w-80 md:w-64 h-full bg-white shadow-lg font-sans p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold text-3xl md:text-lg">Menu</h2>
          <button onClick={onClose} className="text-red-500">
            <FaTimes size={20} />
          </button>
        </div>
        <ul className="flex flex-col gap-4 md:gap-2 text-xl md:text-base">
          <Link to="/practice" onClick={onClose}>
            <li className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
              <FaBookOpen className="mr-2" /> Start Practice
            </li>
          </Link>
          <Link to="/practice/buckets" onClick={onClose}>
            <li className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
              <FaList className="mr-2" /> Question Buckets
            </li>
          </Link>
          <Link to="/practice/bookmarks" onClick={onClose}>
            <li className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
              <FaBookmark className="mr-2" /> Bookmarked Questions
            </li>
          </Link>
          <Link to="/practice/history" onClick={onClose}>
            <li className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
              <FaHistory className="mr-2" /> Question History
            </li>
          </Link>
          <Link to="/practice/profile" onClick={onClose}>
            <li className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
              <FaUser className="mr-2" /> Profile
            </li>
          </Link>
          <li onClick={handleSignOut} className="cursor-pointer hover:bg-gray-300 p-2 rounded flex items-center">
            <FaSignOutAlt className="mr-2" /> Sign out
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TopDrawer;
