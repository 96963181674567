import React, { useState, useEffect } from 'react';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Timer from './Timer'
import NavigationButtons from './NavigationButtons'
import QuestionDetails from './QuestionDetails'
import Explanation from './Explanation'

function SubjectSwitcher({ currentSubject, onSwitchSubject }) {
  
  function capitalizeFirstChar(str) {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="w-full md:w-1/3 mb-6 mt-6 m-2 p-4 md:p-2 flex flex-row gap-2 justify-center rounded shadow bg-white items-center">
      <div className="text-sm text-center text-gray-500">
        Currently practicing:
      </div>
      <div className="text-sm text-center font-semibold">
        {capitalizeFirstChar(currentSubject)}
      </div>
      <button
        className="text-center underline text-blue-500 text-xs"
        onClick={onSwitchSubject}
      >
        (Switch Subject)
      </button>
    </div>
  );
}

function QuestionPractice() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const subject = searchParams.get("subject");
  const questionId = searchParams.get("id");

  const [answerData, setAnswerData] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);


  useEffect(() => {

    async function fetchQuestionData(qid) {
      try {
        let response = await fetch(`/jeet-backend/get-question?question_id=${qid}`)
        
        if (response.status === 401) {
          navigate("/login")
          return
        }

        if (response.status === 422) {
          navigate("/practice")
          return
        }

        if (!response.ok) {
          alert("We are sorry, we are unable to fetch the question at this moment, please try again after some time.")
          console.log("Error response:", response)
          navigate("/practice")
          return
        }

        let data = await response.json()
        return data
      } catch (error) {
        console.error("Error fetching question data:", error)
      }
    }

    function isStringParsableAsInt(str) {
      const parsed = parseInt(str, 10);
      return !isNaN(parsed);
    }
    
    async function handleFetch() {
      if (questionId === undefined && subject === undefined) {
        navigate("/practice")
        return
      }

      if (!isStringParsableAsInt(questionId)) {
        navigate("/practice")
        return
      }

      let subjects = ['chemistry','physics','mathematics']
      if (!subjects.includes(subject)) {
        navigate("/practice")
        return
      }

      let fetchedQuestionObject = await fetchQuestionData(questionId)
      if (fetchedQuestionObject) {
        setQuestionData(fetchedQuestionObject)
      }
    }

    handleFetch();

  }, [questionId, subject]);


  async function getAnswer() {
  
    await fetch(`/jeet-backend/get-answer?question_id=${questionId}`)
    .then(response => response.json())
    .then(data => {
      setAnswerData(data);
    })
    .catch(error => console.error('Error fetching answer data:', error));

  }

  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else if (!isRunning && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, timer]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    addToBucket(parseInt(questionId), parseInt(e.target.value))
  };

  const handleToggleTimer = () => {
    setIsRunning(!isRunning);
  };

  const saveUserHistory = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login')
    }

    try {
      const response = await fetch('/jeet-backend/save-user-history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          question_id: parseInt(questionId),
          time_taken: timer,
        }),
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to save user history');
      }
    } catch (error) {
      console.error("Error saving user history:", error);
    }
  };

  const handlePreviousQuestion = async () => {
    setSelectedOption("")
    window.history.back();
  };

  const handleNextQuestion = async () => {
    setSelectedOption("")
    setShowExplanation(false);
    setTimer(0);
    setIsRunning(true);

    try {
      const response = await fetch(`/jeet-backend/get-new-question?subject=${subject}`, {
        headers: {
          'token': localStorage.getItem('token'),
        },
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch new question');
      }

      const data = await response.json();
      const newQuestionId = data.question_id;

      navigate(`/practice/question?subject=${subject}&id=${newQuestionId}`);
    } catch (error) {
      console.error('Error fetching new question:', error);
    }
  };

  async function addToBucket(questionId, bucketId) {
    const token = localStorage.getItem('token');  // Assuming the token is stored in localStorage
    if (!token) {
      console.error("Token is missing");
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('/jeet-backend/add-to-bucket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          question_id: questionId,
          bucket_id: bucketId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Failed to add question to bucket:', error);
    }
  }

  const handleSubmitAnswer = async () => {
    setIsRunning(false); // Pause timer
    // await saveUserInputs(); // Send elapsed time to backend
    await getAnswer()
    setShowExplanation(true); // Show explanation
    await saveUserHistory()
  };

  const handleSwitchSubject = () => {
    navigate('/practice/subject-selection');
  };

  if (!questionData) {
    return (
      <div className="min-h-screen w-screen bg-gray-100 flex flex-col justify-center items-center p-6">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <MathJaxContext>
      <div className="min-h-screen w-full bg-gray-100 flex flex-col items-center p-6">
        <div className="flex justify-center items-center mt-2 mb-4 w-1/2">
          <Timer timer={timer} isRunning={isRunning} handleToggleTimer={handleToggleTimer} />
        </div>
        <div className="md:w-1/2 bg-white p-6 rounded-lg shadow-lg">
          <QuestionDetails questionData={questionData} />
          <button
            onClick={handleSubmitAnswer}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700 w-full"
          >
            Show Answer
          </button>
          <Explanation showExplanation={showExplanation} answerData={answerData} />
          <NavigationButtons
            handlePreviousQuestion={handlePreviousQuestion} 
            handleNextQuestion={handleNextQuestion} 
            selectedOption={selectedOption} 
            handleOptionChange={handleOptionChange} />
        </div>
        <SubjectSwitcher currentSubject={subject} onSwitchSubject={handleSwitchSubject} />
      </div>
    </MathJaxContext>
  );
}

export default QuestionPractice;
