import React, { useEffect, useState } from 'react';
import BucketTable from './BucketTable';

function QuestionBuckets() {
  const [buckets, setBuckets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the list of buckets from the backend
    const fetchBuckets = async () => {
      try {
        const response = await fetch('/jeet-backend/get-buckets');
        const data = await response.json();
        setBuckets(data);
      } catch (error) {
        console.error('Error fetching buckets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuckets();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-6 space-y-6">
      <div className="text-center text-2xl font-sans">
        Question Buckets
      </div>

      {buckets.map((bucket) => (
        <BucketTable key={bucket.id} bucketId={bucket.id} title={bucket.name} />
      ))}
    </div>
  );
}

export default QuestionBuckets;
