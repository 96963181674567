import { MathJax } from 'better-react-mathjax';

// Explanation Component
function Explanation({ showExplanation, answerData }) {
  return (
    showExplanation && (
      <div className="mt-4">
        <h3 className="text-lg font-bold mb-2">Answer Explanation</h3>
        <MathJax dynamic>
          <div dangerouslySetInnerHTML={{ __html: answerData.explanation }} />
        </MathJax>
        <div className="mt-4">
          <button className="text-red-500 underline py-2 text-xs hover:text-red-600">
            Report explanation as incorrect
          </button>
        </div>
      </div>
    )
  );
}

export default Explanation