import React, { useState } from 'react';

function BookmarkModal({ isOpen, onClose, onSave }) {
  const [note, setNote] = useState("");

  if (!isOpen) return null;

  const handleSave = () => {
    onSave(note);
    onClose();
    setNote("")
  };

  return (
    <div className="z-10 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-[450px]">
        <h2 className="text-xl mb-4">Bookmark this question with a note</h2>
        <textarea
          className="w-full p-2 border rounded mb-4"
          rows="4"
          placeholder="Write your notes or doubts about this question (optional)"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>
        <div className="flex justify-between">
          <button
            className="text-gray-500 underline"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Save Bookmark
          </button>
        </div>
      </div>
    </div>
  );
}

export default BookmarkModal;
