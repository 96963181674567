import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function QuestionList() {
  const [questionIds, setQuestionIds] = useState([]);

  useEffect(() => {
    fetch('/jeet-backend/list-question-ids')
      .then(response => response.json())
      .then(data => setQuestionIds(data))
      .catch(error => console.error('Error fetching question IDs:', error));
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Question IDs</h2>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="border px-4 py-2">Question ID</th>
          </tr>
        </thead>
        <tbody>
          {questionIds.map(id => (
            <tr key={id}>
              <td className="border px-4 py-2">
                <Link to={`/question/${id}`} className="text-blue-500">
                  {id}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default QuestionList;
