import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

function QuestionDetail() {
  const { questionId } = useParams();
  const [questionData, setQuestionData] = useState(null);
  const [answerData, setAnswerData] = useState(null);

  // Fetch the question data
  useEffect(() => {
    fetch(`/jeet-backend/get-question?question_id=${questionId}`)
      .then(response => response.json())
      .then(data => setQuestionData(data))
      .catch(error => console.error('Error fetching question data:', error));
  }, [questionId]);

  // Fetch the answer data
  useEffect(() => {
    fetch(`/jeet-backend/get-answer?question_id=${questionId}`)
      .then(response => response.json())
      .then(data => setAnswerData(data))
      .catch(error => console.error('Error fetching answer data:', error));
  }, [questionId]);

  if (!questionData || !answerData) {
    return <div>Loading...</div>;
  }

  return (
    <MathJaxContext>
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Question Details</h2>
        <div className="bg-white p-4 border rounded">
          <p><strong>ID:</strong> {questionData.id}</p>
          <p><strong>Type:</strong> {questionData.question_type}</p>
          <p><strong>Subject:</strong> {questionData.subject}</p>
          <p><strong>Chapter:</strong> {questionData.chapter}</p>
          <p><strong>Difficulty:</strong> {questionData.difficulty}</p>
          <p><strong>Image path:</strong> {questionData.image_path}</p>
          <div>
            <img src={"http://jeet.local/" + questionData.image_path} alt="Question" />
          </div>
          <div className="mt-4">
            <strong>Answer:</strong>
            <div className="mt-2">
              <MathJax dynamic>
                <div dangerouslySetInnerHTML={{ __html: answerData.explanation }} />
              </MathJax>
            </div>
          </div>

          <div className="mt-4">
            <strong>Raw Answer:</strong>
            <div className="mt-2">
              <MathJax dynamic>
                <pre>
                  {answerData.explanation}
                </pre>
              </MathJax>
            </div>
          </div>

        </div>
      </div>
    </MathJaxContext>
  );
}

export default QuestionDetail;
