import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QuestionList from './QuestionList';
import QuestionDetail from './QuestionDetail';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import VerifyEmailPage from './VerifyEmailPage';
import MainLayout from './MainLayout';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/email-verification" element={<VerifyEmailPage />} />
          <Route path="/questions" element={<QuestionList />} />
          <Route path="/question/:questionId" element={<QuestionDetail />} />

          <Route path="/practice/*" element={<MainLayout />} />
        </Routes>
        <Toaster />
      </div>
    </Router>
  );
}

export default App;
