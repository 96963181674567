import React, { useEffect, useState } from 'react';
import QuestionHistoryTable from './QuestionHistoryTable'; // You'll need to create this component

function QuestionHistoryPage() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // To track if there's more data to load

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        const response = await fetch(`/jeet-backend/get-question-history?page=${page}`, {
          headers: {
            'token': `${token}`
          }
        });
        const data = await response.json();
        
        // If the response has less than the expected number of items, set hasMore to false
        if (data.length < 10) {
          setHasMore(false);
        }

        setHistory((prevHistory) => [...prevHistory, ...data]);
      } catch (error) {
        console.error('Error fetching question history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [page]);

  const handleLoadMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  if (loading && page === 1) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-6 space-y-6">
      <div className="text-center text-2xl font-sans">
        Question History
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md mt-6">
        <QuestionHistoryTable history={history} />

        {loading && page !== 1 ? (
          <p className="text-center">Loading more...</p>
        ) : (
          <div className="flex justify-center mt-6">
            {hasMore ? (
              <button
                onClick={handleLoadMore}
                className="font-sans bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                Load More
              </button>
            ) : (
              <p className="text-gray-500">No more history to load</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionHistoryPage;
