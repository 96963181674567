import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function BucketTable({ title, bucketId }) {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const fetchQuestions = async (pageNumber) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      const response = await fetch(`/jeet-backend/get-questions-by-bucket?bucket_id=${bucketId}&page=${pageNumber}`, {
        headers: {
          token: `${token}`
        }
      });
      const questionIds = await response.json();

      const questionDetailsPromises = questionIds.questions.map(async (qid) => {
        const questionResponse = await fetch(`/jeet-backend/get-question?question_id=${qid}`);
        return questionResponse.json();
      });

      const questionsData = await Promise.all(questionDetailsPromises);
      setQuestions(questionsData);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions(page);
  }, [bucketId, page]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-6">
      <div className="flex items-center mb-4 gap-2">
        <h3 className="font-sans text-lg text-gray-500">Bucket:</h3>
        <h3 className="font-sans text-lg font-bold">{title}</h3>
      </div>
      <div className="flex flex-col overflow-x-scroll">
        <table className="min-w-full bg-white">
          <thead className="font-sans text-gray-500">
            <tr>
              <th className="px-4 py-2">Question ID</th>
              <th className="px-4 py-2">Question</th>
              <th className="px-4 py-2">Type</th>
              <th className="px-4 py-2">Subject</th>
              <th className="px-4 py-2">Chapter</th>
              <th className="px-4 py-2">Source</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <tr 
                className="hover:bg-gray-100 cursor-pointer" 
                key={question.id} 
                onClick={()=>{ navigate(`/practice/question?subject=${question.subject}&id=${question.id}`) }} >
                <td className="border px-4 py-2">{question.id}</td>
                <td className="border px-4 py-2 flex justify-center">
                  <img src={question.image_path} alt="Thumbnail" className="w-60 h-28 object-contain shadow" />
                </td>
                <td className="border px-4 py-2">{question.question_type}</td>
                <td className="border px-4 py-2">{question.subject}</td>
                <td className="border px-4 py-2">{question.chapter}</td>
                <td className="border px-4 py-2">{question.source}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          questions.length === 0 ?
          <div className="w-full flex justify-center font-sans text-sm mt-4 italic">
            No questions found
          </div>
          : null
        }
      </div>
      <div className="flex justify-end mt-4 gap-4">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className="bg-gray-600 text-white text-gray-700 py-2 px-4 rounded hover:bg-gray-400 flex items-center"
        >
          <FaArrowLeft className="" />
        </button>
        <button
          onClick={handleNextPage}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 flex items-center"
        >
          <FaArrowRight className="" />
        </button>
      </div>
    </div>
  );
}

export default BucketTable;
