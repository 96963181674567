import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BookmarksTable({ bookmarks }) {
  const [questionDetails, setQuestionDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      try {
        const fetchedDetails = await Promise.all(
          bookmarks.map(async (bookmark) => {
            const response = await fetch(`/jeet-backend/get-question?question_id=${bookmark.question_id}`);
            const data = await response.json();
            return { ...data, added_on: bookmark.added_on, note: bookmark.note };
          })
        );
        setQuestionDetails(fetchedDetails);
      } catch (error) {
        console.error('Error fetching question details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [bookmarks]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="overflow-x-auto">
      {questionDetails.map((question, index) => (
        <div
          key={index}
          className="flex flex-col md:flex-row border-b border-gray-200 hover:bg-gray-100 cursor-pointer font-sans"
          onClick={() => navigate(`/practice/question?subject=${question.subject}&id=${question.id}`)}
        >
          <div className="flex-shrink-0 w-full md:w-1/2 lg:w-1/3 p-4">
            <img
              src={question.image_path}
              alt="Thumbnail"
              className="w-full h-auto object-contain shadow-lg"
            />
          </div>
          <div className="flex-1 p-4">
            <div className="mb-2">
              <strong className="block text-sm text-gray-600">Note:</strong>
              <p className="text-sm">{question.note || 'No note added'}</p>
            </div>
            <div className="flex flex-col sm:flex-row justify-between mb-2 text-sm text-gray-600">
              <div className="mb-2 sm:mb-0">
                <strong>Type:</strong> {question.question_type}
              </div>
              <div className="mb-2 sm:mb-0">
                <strong>Subject:</strong> {question.subject}
              </div>
              <div className="mb-2 sm:mb-0">
                <strong>Chapter:</strong> {question.chapter}
              </div>
              <div>
                <strong>Source:</strong> {question.source}
              </div>
            </div>
            <div className="text-sm text-gray-500">
              <strong>Added On:</strong> {new Date(question.added_on).toLocaleString()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BookmarksTable;
