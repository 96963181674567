import React, {useEffect} from 'react';
import { FaFlask, FaAtom, FaCalculator, FaRandom } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SubjectSelection() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login')
    }
  },[])

  const handleSubjectSelection = async (subject) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get('/jeet-backend/get-new-question', {
        params: { subject },
        headers: { token },
      });

      const questionId = response.data.question_id;
      navigate(`/practice/question?subject=${subject}&id=${questionId}`);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("navigating to login");
        navigate('/login');
      } else {
        console.error("Error fetching question:", error);
      }
    }
  };

  return (
    <div className="min-h-screen w-screen flex flex-col justify-center items-center bg-gray-100 p-6">
      <h1 className="text-2xl font-bold mb-6">Choose Your Subject</h1>
      <div className="grid grid-cols-2 gap-6 max-w-xl">
        <div
          className="bg-white p-4 rounded-lg shadow-md text-center hover:bg-blue-100 cursor-pointer"
          onClick={() => handleSubjectSelection('physics')}
        >
          <FaAtom size={40} className="mx-auto mb-4 text-blue-500" />
          <p className="text-lg font-semibold">Physics</p>
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md text-center hover:bg-blue-100 cursor-pointer"
          onClick={() => handleSubjectSelection('chemistry')}
        >
          <FaFlask size={40} className="mx-auto mb-4 text-green-500" />
          <p className="text-lg font-semibold">Chemistry</p>
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md text-center hover:bg-blue-100 cursor-pointer"
          onClick={() => handleSubjectSelection('mathematics')}
        >
          <FaCalculator size={40} className="mx-auto mb-4 text-red-500" />
          <p className="text-lg font-semibold">Mathematics</p>
        </div>
        <div
          className="bg-white p-4 rounded-lg shadow-md text-center hover:bg-blue-100 cursor-pointer"
          onClick={() => handleSubjectSelection('random')}
        >
          <FaRandom size={40} className="mx-auto mb-4 text-yellow-500" />
          <p className="text-lg font-semibold">Random</p>
        </div>
      </div>
    </div>
  );
}

export default SubjectSelection;
